<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        :rules="rules"
        clearable
        v-on="on"
        @click:clear="clear"
        @input="onChange"
      ></v-text-field>
    </template>
    <v-date-picker
      v-if="menu"
      :value="value"
      :first-day-of-week="1"
      locale="ru-ru"
      :min="min"
      :max="max"
      @input="onChange"
    ></v-date-picker>
  </v-menu>
</template>
<script>

export default {
  name: 'DropDownDatePicker',
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    clear() {
      this.date = null
      this.menu = false
      this.$emit('input', null)
    },
    onChange(v) {
      this.menu = false
      this.$emit('input', v)
    }
  }
}
</script>
